.clickable {
    cursor: pointer;
}

.iconLabelContainer {
    display: flex;
    align-items: center;
}

.label {
    margin-right: 15px;
}

.iconPrimary svg * {
    fill: url(#SVGLinearGradientBlue);
}
