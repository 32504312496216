@import "src/styles/variables";

.icon-container.is-round {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid $c-slate-grey;
    transition: border-color 0.3s;

    &:hover {
        border-color: $c-dark-purple;
        color: $c-dark-purple;
    }

    .icon {
        transition: color 0.3s;
    }
}

.icon-container.is-clickable {
    cursor: pointer;
}
