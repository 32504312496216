@import "src/styles/variables";

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 25px;
    border: 1px dashed $c-greyish-blue;
}

.button {
    padding: 4px 10px;
    border-radius: 50% !important;

    &:before {
        border-radius: 50% !important;
    }
}
