@import "src/styles/variables";

.root {
    margin-bottom: 60px;

    :global(.loader-container) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 159px;
        height: 90px;
        border-radius: 5px;
        border: 1px solid $c-greyish-blue;

        :global(.loader:after) {
            top: calc(50% - 0.5em);
            left: calc(50% - 0.5em);
            width: 1em;
            height: 1em;
            border-width: 0.1em;
        }
    }
}

.deleteButtonContainer {
    text-align: right;
}

.editButtonContainer {
    text-align: right;
    min-height: 24px;
}

.editButtonContainer span {
    margin-right: 10px;
}

.deleteButton {
    cursor: pointer;
}

.spacer {
    height: 23px;
}

.title {
    margin-right: 15px;
}

.tileAncestor {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 10px 24px;

    :global(.photo-pair-container) {
        flex: 0 0 auto;
    }
}

.selectionCount {
    position: absolute;
    top: -15px;
    right: -15px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: #ffffff;
    background-image: linear-gradient(
        10deg,
        #3494fe 0%,
        #75e4fd 100%,
        #75e4fd 100%
    );
    box-shadow: 0 2px 8px 0 rgba(100, 200, 250, 0.5);
    font-weight: 700;
    z-index: 2;
}
