.label {
    text-align: left;
}

.backButton {
    display: flex;
    align-items: center;

    :global(div) {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    :global(.icon) {
        margin-right: 5px;
    }
}
