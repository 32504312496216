.clickable {
    cursor: pointer;
}

.level-item {
    margin-right: 5px;
}

.alignCenter {
    display: flex;
    align-items: center;
}

.disabled {
    color: rgba(147, 156, 161, 0.5) !important;
    cursor: not-allowed;
}
