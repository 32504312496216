@import "src/styles/variables";

.container {
    max-width: 293px;
    margin-right: 40px;

    .blockIndex {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 5px;
        border: 1px solid $c-slate-grey;
        border-radius: 50%;
        background-color: #fff;
        margin: 0 5px 0 5px;
        font-weight: 500;
        color: $c-slate-grey;
    }

    .extraHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        padding: 5.5px;
        cursor: pointer;
        border: 1px dashed $c-greyish-blue;
        border-radius: 4px;
        background-color: #ffffff;
        color: #c7d1d5;

        &:hover {
            border-color: #90989c;
            color: #90989c;
        }

        :global(.icon) {
            font-size: 15px;

            &:nth-child(2) {
                margin-right: 8px;
            }

            &:nth-child(2),
            &:nth-child(4) {
                margin-left: -4px;
                font-size: 10px;
            }
        }

        &.isPink {
            border: none;
            color: #ffffff;
        }

        &.isEmpty {
            cursor: initial;
            opacity: 0;
            pointer-events: none;
        }
    }

    .header {
        padding: 8px;
        border-radius: 4px;
        margin-bottom: 24px;
    }

    .isGray {
        background-image: $grey-gradient;
    }

    .isPurple {
        background-image: $purple-gradient;
        box-shadow: 0 0 11px 0 rgba(151, 140, 215, 0.5);
    }

    .isPink {
        box-shadow: 0 4px 10px 0 rgba(250, 125, 170, 0.5);
        background-image: $pink-gradient;
    }

    .isDisabled {
        cursor: initial;
        pointer-events: none;
    }

    .title {
        display: block;
        font-size: 15px;
        letter-spacing: 0;
        text-align: center;
        color: #fff;
    }

    .body {
        position: relative;

        .photoTileActive:global(.tile.is-ancestor) {
            position: relative;

            &:after {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background-color: rgba(255, 255, 255, 0.7);
                content: "";
            }

            .selectIconContainer {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 1px solid $c-slate-grey;
                border-radius: 50%;
                background-color: #fff;
                color: #27bd62;
                z-index: 2;

                :global(.icon) {
                    font-size: 12px;
                }
            }
        }

        :global(.tile.is-ancestor) {
            padding: 5px;
            border: 1px solid $c-greyish-blue;
            border-radius: 4px;
            background-color: #fff;
        }

        :global(.tile.is-parent) {
            padding: 5px;
        }

        :global(.tile.is-vertical > .tile.is-child:not(:last-child)) {
            margin-bottom: 10px !important;
        }

        .mainImageLabel {
            display: grid;
            height: 100%;
            align-items: end;
            text-align: center;

            span {
                background-color: rgba(0, 0, 0, 0.4);
                font-size: 14px;
                font-weight: bolder;
                color: white;
            }
        }

        .mediaObject {
            width: 95px;
            height: 74px;
            border-radius: 4px;
            border: 1px solid $c-greyish-blue;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: #f5f9fb;

            &.isSingle {
                width: 100%;
                height: 96%;
                margin-top: 3px;
            }

            &.isDouble {
                width: 100%;
            }
        }

        .videoObject {
            position: relative;
            width: 210px;
            height: 170px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $c-greyish-blue;
            background-color: #f5f9fb;

            &.isSingle {
                width: 100%;
                height: 100%;
            }

            :global(.icon) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            :global(.loader:after) {
                top: calc(50% - 0.5em);
                left: calc(50% - 0.5em);
                width: 1em;
                height: 1em;
                border-width: 0.1em;
            }
        }
    }

    .photoTileAncestor {
        min-width: 293px;
        min-height: 182px;

        &.isSmall {
            min-height: 65px;
        }
    }

    .editButton {
        position: absolute;
        right: -16px;
        bottom: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        min-width: 30px;
        min-height: 30px;
        border-radius: 19px;
        background-image: $blue-gradient;
        letter-spacing: 0;
        color: #fff;
        transition: box-shadow 0.4s;

        &:hover,
        &:focus {
            box-shadow: 0 10px 20px 0 rgba(100, 200, 250, 0.5);
        }

        &.hasMore {
            padding-left: 12px;
            padding-right: 6px;
        }

        .editButtonLabel {
            margin-right: 14px;
            font-size: 15px;
            font-weight: 500;
        }

        :global(.icon) {
            font-size: 14px;
        }
    }

    .photoLoader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-radius: 4px;
        border: 1px solid $c-greyish-blue;

        :global(.loader:after) {
            top: calc(50% - 0.5em);
            left: calc(50% - 0.5em);
            width: 1em;
            height: 1em;
            border-width: 0.1em;
        }
    }

    .blockIndicatorContainer {
        display: flex;
        flex-direction: row;
        margin: 0 auto 20px auto;
        justify-content: center;
    }
}
