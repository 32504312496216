@import "src/styles/variables";

.container {
    margin-top: auto;
    margin-bottom: auto;
}

.text {
    margin: 0 auto;
    max-width: 320px;
    color: $c-dark-purple;
}
