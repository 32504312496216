@import "src/styles/variables";

.outerContainer {
    background-image: $purple-dark-gradient;
}

.container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 30px 0;

    :global(.dropdown-trigger .button) {
        background-color: transparent;
        color: white;
        border: none;
        text-transform: none;
    }
}

.novellaLogo {
    height: 40px;
}

.extraLogo {
    height: 40px;
}
