.titleLevel:not(:last-child) {
    margin-bottom: 40px;
}

.searchLevel:not(:last-child) {
    margin-bottom: 0;

    :global(.level-left) {
        flex-grow: 1;
        max-width: 450px;
    }

    :global(.field) {
        width: 100%;
    }

    :global(.level-right .level-item:not(:last-of-type)) {
        margin-right: 75px;
    }
}

.loaderContainer {
    margin-top: 100px;
}

.warningContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    :global {
        .icon {
            margin-bottom: 10px;
        }
    }
}
