@import "src/styles/variables";

.modalCard {
    width: 800px;
}

.container {
    display: flex;
}

.addPhotoField {
    margin-top: 20px;
    max-width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.draggableRoot {
    position: relative;
    margin-right: 20px;

    .photoHeader {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background-color: #fff;
        width: 30px;
        height: 30px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $c-slate-grey;

        .photoHeaderDragHandle {
            display: none;
        }
    }

    %sideButton {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        padding-left: 4px;
        padding-right: 4px;
        background-color: #f5f9fb;
        font-size: 12px;
        opacity: 0;
        transition: 0.2s opacity;
        pointer-events: none;
    }

    .deleteButtonContainer {
        @extend %sideButton;

        right: -1px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .editButtonContainer {
        @extend %sideButton;

        left: -1px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:hover {
        .deleteButtonContainer,
        .editButtonContainer {
            opacity: 1;
            pointer-events: initial;
            cursor: pointer;
        }

        .photoHeader {
            :global(.icon) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $c-slate-grey;
                line-height: 1;
            }

            .photoHeaderDragHandle {
                display: block;
            }

            .photoHeaderIndex {
                display: none;
            }
        }
    }
}

.mediaPreviewContainer {
    display: flex;
    justify-content: center;
}

.audioPlayer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.photoListOuterContainer {
    width: 100%;
    overflow: auto;
}

.photoListContainer {
    display: flex;
    margin-top: 40px;
}

.photoListInterval {
    text-align: center;
}

.mediaPreview {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 0 5px 0 rgba(70, 35, 100, 0.1);
    background-color: #ffffff;
    margin-bottom: 20px;

    .mediaPreviewIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border: 1px solid #9b9b9b;
        border-radius: 50%;
        background: #fbfbfb;
        color: $c-slate-grey;

        :global(.icon) {
            font-size: 14px;
        }
    }

    .mediaPreviewTitle {
        display: block;
        min-width: 140px;
        margin-right: 15px;
        font-size: 15px;
        font-weight: 500;
        color: $c-shuttle-gray;
        letter-spacing: 0;
        line-height: 25px;
    }

    .mediaPreviewButton {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        min-width: 30px;
        min-height: 30px;
        border-radius: 19px;
        background-image: $blue-gradient;
        letter-spacing: 0;
        color: #fff;
        transition: box-shadow 0.4s;

        &:hover,
        &:focus {
            box-shadow: 0 10px 20px 0 rgba(100, 200, 250, 0.5);
        }

        .editButtonLabel {
            margin-right: 14px;
            font-size: 15px;
            font-weight: 500;
        }

        :global(.icon) {
            font-size: 14px;
        }
    }

    .mediaDeleteButton {
        @extend .mediaPreviewButton;

        background-image: $pink-gradient;
        margin-right: 15px;
    }
}

.durationFieldContainer {
    max-width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.mediaObject {
    width: 170px;
    height: 96px;
    border-radius: 4px;
    border: 1px solid $c-greyish-blue;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #f5f9fb;

    &.isSingle {
        width: 100%;
        height: 96%;
        margin-top: 3px;
    }

    &.isDouble {
        width: 100%;
    }
}
