.toasts {
    bottom: 24px;
    position: fixed;
    right: 24px;
    z-index: 9999;
}

.enter {
    opacity: 0;
    transform: translateX(100px);
}

.enterActive {
    opacity: 1;
    transform: translateX(0);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.exit {
    opacity: 1;
    transform: translateX(0);
}

.exitActive {
    opacity: 0;
    transform: translateX(100px);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
