@import "src/styles/variables";

.explanation {
    font-size: 15px;
    color: $c-dark-purple;
    text-align: center;
    margin-bottom: 30px;

    :global(p:first-child) {
        margin-bottom: 15px;
    }
}

.contactTitle {
    text-align: center;
}

.contactDetails {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}
