.clickable {
    cursor: pointer;
}

.logo {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.detailsButton {
    display: block;
    margin: 0 auto;
}

.icon {
    margin-right: 10px;
}

.alignCenter {
    display: flex;
    align-items: center;
}
