.modal {
    :global(.modal-card) {
        width: 740px;
    }
}

.cropContainer {
    width: 100%;
    margin-bottom: 25px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.cropContainer img {
    max-height: 500px;
}

.rotateButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
