.modal-card-head .modal-card-title {
    margin-left: 20px;
    text-align: center;
}

.modal-card-foot {
    display: flex;
    justify-content: center;
    align-items: center;
}
