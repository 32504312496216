@import "src/styles/variables";

.description {
    text-align: center;
    margin-bottom: 45px;
}

.loader {
    width: 24px;
    height: 24px;

    &::after {
        top: calc(50% - 0.5em);
        left: calc(50% - 0.5em);
        width: 1em;
        height: 1em;
        border-width: 0.1em;
    }
}

.listContainer {
    margin-bottom: 30px;
}

.listItem {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(114, 114, 114, 0.3);
    margin-bottom: 10px;

    .listItemDetails {
        flex: 1;

        .listItemName {
            font-size: 15px;
            font-weight: 500;
            color: $c-shuttle-gray;
            letter-spacing: 0;
            line-height: 25px;
        }

        .listItemEmail {
            font-size: 15px;
            font-weight: 300;
            color: $c-shuttle-gray;
            letter-spacing: 0;
            line-height: 20px;
        }
    }

    .listItemActions {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.icon {
    &:not(:last-of-type) {
        margin-right: 10px;
    }
}

.clickable {
    cursor: pointer;
}

.field {
    display: flex;
    align-items: center;

    .inputContainer {
        flex: 1;
    }

    .deleteButton {
        margin-left: 24px;
        cursor: pointer;
    }
}
