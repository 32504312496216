.clickable {
    cursor: pointer;
}

.loaderContainer {
    margin-top: 100px;
}

.icon {
    margin-right: 10px;
}

.icon:hover {
    color: #140528;
}
