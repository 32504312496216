.table.is-hoverable {
    border-collapse: separate;
    border-spacing: 0 30px;

    tbody tr:not(.is-selected) {
        &:hover {
            td {
                background-color: #fff;
            }
        }

        td {
            padding-top: 15px;
            padding-bottom: 15px;
            transition: background-color 0.2s;
            vertical-align: middle;

            &:not(:last-child) {
                margin-bottom: 15px;
            }

            &:first-child {
                padding-left: 20px;
                border-left-style: solid;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
            }

            &:last-child {
                padding-right: 20px;
                border-right-style: solid;
                border-bottom-right-radius: 50px;
                border-top-right-radius: 50px;
            }

            .icon {
                color: $c-slate-grey;
                transition: color 0.25s;

                &.is-clickable:hover {
                    color: $c-dark-purple;
                }
            }
        }
    }
}
