.field:not(:last-of-type) {
    margin-bottom: 30px;
}

.field {
    :global(.label) {
        font-weight: 700;
    }
}

.submitButtonContainer {
    width: 80%;
}

.submitBtn {
    display: block;
    width: 100%;
    margin: 15px auto 40px;
}
