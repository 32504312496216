@import "../../styles/variables";

.addButtonContainer {
    display: flex;
    justify-content: center;
}

.trashIcon {
    cursor: pointer;
    pointer-events: all !important;
}

.textarea:read-only {
    background-image: $white-gradient;
    min-height: 80px;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    align-items: center;
}
