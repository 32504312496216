@import "src/styles/variables";

.backButton {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    :global(div) {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}

.backButton :global(.icon) {
    margin-right: 5px;
}

.ceremonyLabel {
    margin-bottom: 15px;
}

.roundIconsContainer {
    margin-right: 40px !important;
}

.roundIconButtonActive {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 30px;
    min-height: 30px;
    border: 1px solid #6340a5;
    border-radius: 19px;
    letter-spacing: 0;
    color: #6340a5;
    transition: box-shadow 0.4s;

    &:not(:last-of-type) {
        margin-right: 10px;
    }

    :global(.icon) {
        font-size: 14px;
    }
}

.relativesCount {
    min-width: 22px;
    line-height: 22px;
    border-radius: 50%;
    margin-right: 15px;
    background-color: #f5f9fb;
    letter-spacing: 0;
    font-weight: 500;
    font-size: 13px;
    color: $c-shuttle-gray;
}

.mainImage {
    background-size: contain;
    margin-right: 20px;
    border-radius: 5px;
    height: 22px;
    width: 40px;
    scale: (1.4);
}

.relativesButtonLabel {
    margin-right: 10px;
}

.mainImageButtonLabel {
    margin-right: 10px;
}
