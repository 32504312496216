@import "src/styles/variables";

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item {
    display: block;
    width: 50px;
    height: 28px;
    padding: 0;
    opacity: 0.5;
    border: 1px solid $c-slate-grey;
    border-radius: 2px;
    background-color: transparent;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.active {
    opacity: 1;

    &:hover {
        opacity: 1;
    }
}
