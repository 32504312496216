@import "src/styles/variables";

.modal {
    .field {
        display: flex;
        align-items: center;
    }

    .inputContainer {
        flex: 1;
    }

    .deleteButton {
        margin-left: 24px;
        cursor: pointer;
    }
}

.listContainer {
    margin-bottom: 30px;
}

.listItem {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(114, 114, 114, 0.3);
    margin-bottom: 10px;

    .listItemDetails {
        flex: 1;

        .listItemName {
            font-size: 15px;
            font-weight: 500;
            color: $c-shuttle-gray;
            letter-spacing: 0;
            line-height: 25px;
        }

        .listItemEmail {
            font-size: 15px;
            font-weight: 300;
            color: $c-shuttle-gray;
            letter-spacing: 0;
            line-height: 20px;
        }
    }

    .listItemActions {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.clickable {
    cursor: pointer;
}
