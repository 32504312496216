@import "src/styles/variables";

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.doubleItems {
        justify-content: center;

        .item {
            margin-right: 24px;
        }
    }
}

.item {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 180px;
    height: 140px;
    background-image: $white-gradient;
    border: 1px solid $c-greyish-blue;
    border-radius: 10px;
    transition: border-color 0.2s;

    &:hover {
        border-color: #3d9ffe;
        border-width: 2px;
    }
}

.icon {
    margin-bottom: 10px;
    width: 50px;
}

.modal {
    :global(.modal-card) {
        width: 740px;
    }
}
