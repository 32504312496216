.react-datepicker-popper {
    z-index: 5; /* Make sure datepicker is above Bulma's dropdown arrows */
}

.react-datepicker__day--selected {
    background-color: $c-purple;
}

.react-datepicker__day--selected:hover {
    background-color: #2f1744;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: $c-purple;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: #2f1744;
}
