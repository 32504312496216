@import "src/styles/variables";

.modal {
    :global(.modal-card-body) {
        text-align: center;
        color: $c-dark-purple;

        p:first-of-type {
            margin-bottom: 20px;
        }
    }

    label input[type="checkbox"] {
        margin: 20px 20px 0 0;
    }
}
