@import "src/styles/variables";

.field {
    :global(.control .icon) {
        color: $c-shuttle-gray;
    }

    :global(.react-datepicker-wrapper) {
        width: 100%;
    }
}

.dateInput {
    width: 100%;
}
