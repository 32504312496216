.select select {
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    border-radius: 25px;
    font-size: 15px;
    font-weight: 300;
    color: $c-shuttle-gray;
}

.select:not(.is-multiple):not(.is-loading)::after {
    border-color: $c-slate-grey;

    &:hover {
        border-color: $c-slate-grey;
    }
}
