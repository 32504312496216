$primary: $c-purple;
$family-sans-serif: "aktiv-grotesk", sans-serif;
$family-secondary: "Helder", sans-serif;

$label-color: $c-dark-purple;

$button-color: $c-shuttle-gray;
$button-hover-color: #4c5960;
$button-border-color: $c-greyish-blue;
$button-border-width: 1px;
$button-family: "aktiv-grotesk", sans-serif;
$button-padding-vertical: calc(0.75em - #{$button-border-width});
$button-padding-horizontal: 1.5em;

$input-color: $c-shuttle-gray;
$input-placeholder-color: $c-slate-grey;
$input-border-color: $c-greyish-blue;

$label-color: $c-dark-purple;
$label-weight: 400;

$message-radius: 10px;
$message-header-radius: 10px;
$message-body-color: $c-shuttle-gray;

$modal-card-head-background-color: #ffffff;
$modal-card-head-border-bottom: 1px solid #f2f2f2;
$modal-card-foot-border-top: 1px solid #f2f2f2;
$modal-content-width: 445px;
$modal-card-body-padding: 30px 48px;

$table-color: $c-shuttle-gray;
$table-background-color: transparent;
$table-row-hover-background-color: transparent;
$table-cell-border: none;

$pagination-border-color: transparent;
$pagination-color: $c-shuttle-gray;
$pagination-hover-border-color: transparent;

$pagination-current-background-color: transparent;
$pagination-current-border-color: $c-slate-grey;
$pagination-current-color: $c-shuttle-gray;
