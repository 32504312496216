@import "src/styles/variables";

.tileAncestor {
    flex-wrap: wrap;
    max-height: 35vh;
    overflow-y: auto;
}

.image {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;

    :global img {
        max-height: 60px;
    }
}

.emptyImage {
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid $c-greyish-blue;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.block {
    border: 1px solid red;
    margin: 10px;
}

.blockList {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 10px 24px;

    :global(.block) {
        flex: 0 0 auto;
    }
}

.addBlockButton {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-top: 96px;
    margin-right: 40px;
    border: 1px dashed $c-greyish-blue;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        border-color: #90989c;

        :global(.icon) {
            color: #90989c;
        }
    }

    :global(.icon) {
        font-size: 10px;
        color: $c-greyish-blue;
    }
}
