@import "src/styles/variables";

.uploadContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border-radius: 25px;
    border: 1px dashed $c-greyish-blue;
}

.uploadInput {
    height: 100%;
    left: 0;
    opacity: 0;
    outline: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.uploadButton {
    z-index: 0 !important;
}

.preview {
    display: flex;
    justify-content: center;
    align-items: center;
}

.previewInnerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid $c-greyish-blue;
    border-radius: 5px;

    :global(img) {
        max-width: 200px;
        min-height: 40px;
        margin-right: 10px;
    }
}

.imagePreviewDeleteButton {
}

.audioPreviewDeleteButton {
    cursor: pointer;
}
