@import "src/styles/variables";

.container {
    padding-top: 30px;
    padding-bottom: 30px;
}

.row {
    display: flex;
    align-items: center;

    div {
        flex: 1;
    }

    div:first-child {
        margin-right: auto;
        text-align: left;
    }

    div:nth-child(2) {
        flex-grow: 1;
        text-align: center;
    }

    div:last-child {
        margin-left: auto;
        text-align: right;
    }

    span {
        font-size: 12px;
        color: $c-dark-purple;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
}
