.modalCard {
    width: 800px;
}

.title {
    display: block;
    margin: 0 auto 60px auto;
    max-width: 450px;
    text-align: center;
}

.photoList {
    display: flex;
    flex-wrap: wrap;
    max-height: 280px;

    :global(> div) {
        margin-bottom: 15px;
        margin-right: 15px;
    }
}
