.indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: background-color 0.25s;
}

.isLocked {
    background-color: #ffdd57;
}

.isInactive {
    background-color: #b11444;
}

.isActive {
    background-color: #27bd62;
}
