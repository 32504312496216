.modal {
    :global(.modal-card) {
        width: 740px;
    }
}

.AdjustmentContainer {
    width: 100%;
    margin-bottom: 25px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.AdjustmentContainer img {
    max-height: 500px;
}
