.label {
    font-size: 15px;
    font-weight: 300;

    &:hover {
        color: inherit;
    }

    &.reverse {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        .input {
            margin-left: 0;
            margin-right: 15px;
        }
    }
}

.input {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 15px;
    vertical-align: middle !important;
}
