@keyframes toastProgress {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.message.is-toast {
    max-width: 440px;

    &.is-danger {
        .message-header {
            background-color: #b11444;
        }
    }

    &.is-success {
        .message-header {
            background-color: #27bd62;
        }
    }

    .message-header {
        p {
            margin-left: auto;
            margin-right: auto;
            font-size: 20px;
        }
    }

    .message-body {
        position: relative;
        background-color: #fff;
        color: $c-shuttle-gray;
        font-weight: 500;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 4px;
            background-color: rgba(0, 0, 0, 0.25);
            content: "";
            animation: toastProgress 5s linear;
        }
    }
}
