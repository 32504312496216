.removalBlockArea {
    margin-top: 30px;
    background-color: #ffdfdf;
    border: 1px dashed #b11444;
    padding: 80px;
    max-height: 192px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.isActive {
        background-color: #e3bcbc;
    }

    :global(.icon) {
        margin-right: 20px;
        font-size: 20px;
        color: #b11444;
    }

    :global(h2) {
        font-weight: 400;
        color: #b11444;
        text-align: center;
    }
}
