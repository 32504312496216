.warningContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    :global {
        .icon {
            margin-bottom: 10px;
        }
    }
}
