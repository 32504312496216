@font-face {
    font-family: "Helder";
    src: url("../../assets/fonts/Helder-bold.otf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Helder";
    src: url("../../assets/fonts/Helder-regular.otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Helder";
    src: url("../../assets/fonts/Helder-light.otf");
    font-weight: 300;
    font-style: normal;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: "aktiv-grotesk", sans-serif;
    font-weight: 300;
    color: $c-shuttle-gray;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: "Helder", sans-serif;
    font-weight: 700;
    color: $c-purple;
}

h1,
.h1 {
    font-size: 28px;
}

h2,
.h2 {
    font-size: 20px;
}

h3,
.h3 {
    font-size: 18px;
}

h4,
.h4 {
    font-size: 18px;
    font-weight: 400;
}

.link {
    font-size: 14px;
    font-weight: 400;
    color: $c-slate-grey;
}
