.deleteButton {
    cursor: pointer;
    pointer-events: initial !important;
}

.fieldSmallerMargin {
    margin-bottom: 15px !important;
}

.label {
    text-align: left;
}
