.checkboxContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;

    :global(.checkbox) {
        margin-bottom: 15px;
    }
}

.deleteButton {
    cursor: pointer;
}

.loaderContainer {
    margin-top: 30px;
    min-height: 75px;
}
