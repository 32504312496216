.loader {
    position: relative;
    margin: 0 auto;
    border: none;
    pointer-events: none;

    &::after {
        @include loader;

        border-left-color: $c-purple;
        border-bottom-color: $c-purple;
        position: absolute;
        top: calc(50% - 2.5em);
        left: calc(50% - 2.5em);
        width: 5em;
        height: 5em;
        border-width: 0.25em;
    }
}
