.previewContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
}

.deleteBtn {
    background-color: #ff3860;
    position: absolute;
    right: 5px;
    top: 5px;

    &:hover {
        background-color: #ff2b56;
    }
}

.noResults {
    display: inline-block;
    margin-bottom: 40px;
}
