.pagination {
    font-weight: 500;
    justify-content: center;
}

.pagination-list {
    flex-grow: initial;
    flex-shrink: initial;
}

.pagination-link.is-current {
    border-radius: 50%;
}

.pagination-previous,
.pagination-next {
    border: 1px solid $c-slate-grey;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    &:hover {
        border: 1px solid $c-slate-grey;
    }
}
