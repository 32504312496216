.backButton {
    display: flex;
    align-items: center;

    :global(div) {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    :global(.icon) {
        margin-right: 5px;
    }
}

.linkContainer button:not(:last-child) {
    margin-right: 15px;
}

.searchLevel:not(:last-child) {
    margin-bottom: 0;

    :global(.level-left) {
        flex-grow: 1;
        max-width: 450px;
    }

    :global(.field) {
        width: 100%;
    }
}

.loaderContainer {
    margin-top: 100px;
}

.warningContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    :global {
        .icon {
            margin-bottom: 10px;
        }
    }
}
