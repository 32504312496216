$c-purple: #462364;
$c-dark-purple: #140528;
$c-slate-grey: #939ca1;
$c-shuttle-gray: #59646a;
$c-greyish-blue: #c2ccd1;

$blue-gradient: linear-gradient(-45deg, #3494fe 0%, #75e4fd 100%);
$grey-gradient: linear-gradient(135deg, #8d979d 0%, #737d83 100%);
$purple-gradient: linear-gradient(270deg, #978dd7 0%, #6340a5 100%);
$purple-dark-gradient: linear-gradient(
    134deg,
    $c-purple 0%,
    $c-dark-purple 100%
);
$pink-gradient: linear-gradient(135deg, #fa84af 0%, #f8528f 100%);
$white-gradient: linear-gradient(#f1f6f9, #ffffff);
$white-hover-gradient: linear-gradient(#dde7ec, #f1f6f9);
