@import "../../styles/variables";

.comment {
    background-image: $white-gradient;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid $c-slate-grey;
    white-space: pre-wrap;
}
