.modalRoot {
    z-index: 41;
}

.contentContainer {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
}

.warningIcon {
    margin-bottom: 20px;
}
