.upload-modal {
    &.is-uploading {
        .modal-card-head {
            z-index: 9;
        }

        .modal-card:after {
            display: block;
        }

        .upload-loader-container {
            display: block;
        }

        .progress:not(.song-progress) {
            display: block;
        }
    }

    .modal-card {
        position: relative;

        &:after {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.9;
            border-radius: 6px;
            background-color: #ffffff;
            z-index: 10;
            content: "";
        }
    }

    .upload-loader-container {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 13;
    }

    .upload-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-radius: 4px;

        &:after {
            top: calc(50% - 0.75em);
            left: calc(50% - 0.75em);
            width: 1.5em;
            height: 1.5em;
            border-width: 0.1em;
            border-left-color: #939ca1;
            border-bottom-color: #939ca1;
        }
    }

    .progress:not(.song-progress) {
        display: none;

        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 15px;
        background-color: #c2ccd1;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        z-index: 12;

        .progress-bar {
            position: relative;
            display: block;
            height: 100%;
            overflow: hidden;
            background-image: linear-gradient(270deg, #978dd7 0%, #6340a5 100%);
            box-shadow: 0 0 44px 0 rgba(151, 140, 215, 0.5);
            transition: width 350ms ease;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}
