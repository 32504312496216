.container {
    .select {
        display: block;
        width: 100%;
        height: auto !important;

        :global(select) {
            width: 100%;
            height: auto !important;
            padding-top: 11.5px;
            padding-bottom: 11.5px;
            padding-left: 2em;
        }
    }
}
