.detailsButton {
    display: block;
    margin: 0 auto;
}

.icon {
    margin-right: 10px;
}

.alignCenter {
    display: flex;
    align-items: center;
}

.clickable {
    cursor: pointer;
}
