@import "src/styles/variables";

.modalCard {
    width: 540px;
}

.progressContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    .progressItem {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 50%;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        color: $c-slate-grey;

        &.prev {
            opacity: 0.5;
        }

        &.prev,
        &.current {
            border: 1px solid $c-slate-grey;
        }

        &.next {
            border: 1px dashed $c-slate-grey;
        }
    }

    .progressDash {
        width: 20px;
        height: 1px;

        &.prev {
            border-top: 1px solid $c-slate-grey;
            opacity: 0.5;
        }

        &.current,
        &.next {
            border-top: 1px dashed $c-slate-grey;
        }
    }
}

.content {
    text-align: center;
}
