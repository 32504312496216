.backButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    :global(.icon) {
        margin-right: 5px;
    }
}

.linkContainer button:not(:last-child) {
    margin-right: 15px;
}

.warningContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    :global {
        .icon {
            margin-bottom: 10px;
        }
    }
}
