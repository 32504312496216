@import "src/styles/variables";

.roundIconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 30px;
    min-height: 30px;
    border: 1px solid $c-slate-grey;
    border-radius: 19px;
    letter-spacing: 0;
    color: $c-slate-grey;
    transition: box-shadow 0.4s;

    &:not(:last-of-type) {
        margin-right: 10px;
    }

    :global(.icon) {
        font-size: 14px;
    }
}
