.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    :global(.loader:after) {
        top: calc(50% - 0.5em);
        left: calc(50% - 0.5em);
        width: 1em;
        height: 1em;
        border-width: 0.1em;
    }
}
