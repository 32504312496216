@import "src/styles/variables";

.container {
    position: relative;
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(70, 35, 100, 0.1);
    border-radius: 50px;
    padding: 10px;
    margin-bottom: 20px;

    &.selected {
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            display: block;
            background: rgba(245, 249, 251, 0.7);
            border-radius: 50px;
            content: "";
        }

        .selectedIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid $c-slate-grey;
            border-radius: 50%;
            background-color: #fff;
            color: #27bd62;
            z-index: 3;

            :global(.icon) {
                width: 2rem;
                height: 2rem;
                font-size: 16px;
            }
        }
    }
}

.actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    transition: border-color 0.2s;

    &:before {
        border-radius: 50%;
    }

    &:hover {
        :global(.icon) {
            color: #4c5960;
        }
    }

    :global(.icon) {
        transition: border-color 0.2s;
        color: #59646a;
    }
}

.action {
    margin-right: 10px;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;

    .meta {
        display: flex;
        align-items: center;

        .name {
            display: flex;
            flex-direction: column;
            flex: 1;
            min-width: 0;

            .title {
                font-size: 15px;
                font-weight: 500;
                color: #646f75;
                letter-spacing: 0;
                line-height: 25px;
                min-width: 0;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .subtitle {
                font-size: 14px;
                font-weight: 400;
                color: #59646a;
                letter-spacing: 0;
                line-height: 16px;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .duration {
            flex-basis: 75px;
            font-size: 12px;
            font-weight: 400;
            color: #7a868c;
            letter-spacing: 0;
            text-align: right;
            line-height: 16px;
        }
    }

    .progress {
        flex: 1;

        .bar {
            position: relative;
            width: 100%;
            height: 6px;
            border-radius: 3px;
            background-color: #efefef;
            cursor: pointer;

            &:after {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                height: 10px;
                transform: translateY(-50%);
                content: "";
            }

            .innerBar {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                border-radius: 3px;
                background-color: transparent !important;
                background-image: $blue-gradient !important;
                box-shadow: 0 2px 8px 0 rgba(100, 200, 250, 0.5);
                transition: width 100ms;
            }
        }
    }
}
