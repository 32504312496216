.button {
    height: auto;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: border-color 0.25s, color 0.25s;

    &.is-rounded {
        font-size: 14px;
        padding-top: 15px;
        padding-right: 30px;
        padding-bottom: 15px;
        padding-left: 30px;
    }

    &.is-primary {
        border: none;
        background-color: transparent;
        background-image: $blue-gradient;
        letter-spacing: 2px;
        transition: box-shadow 0.4s;

        &:hover,
        &:focus {
            box-shadow: 0 10px 20px 0 rgba(100, 200, 250, 0.5);
        }
    }

    &.is-small {
        font-size: 10px;
        padding-top: 6px;
        padding-right: 10px;
        padding-bottom: 6px;
        padding-left: 10px;
    }

    &.is-secondary {
        position: relative;
        background-image: $white-gradient;
        z-index: 1;

        &.is-rounded::before {
            border-radius: $radius-rounded;
        }

        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: $white-hover-gradient;
            z-index: -1;
            transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            opacity: 0;
        }

        &:hover::before {
            opacity: 1;
        }
    }
}
