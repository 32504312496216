.field:not(:last-of-type) {
    margin-bottom: 30px;
}

.field {
    :global(.label) {
        font-weight: 700;
    }
}

.submitBtnContainer {
    display: flex;
    justify-content: center;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 35px;
}

.submitBtn {
    width: 100%;
}
