@import "src/styles/variables";

.modalCard {
    width: 800px;

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        .box {
            flex: 1;
            display: flex;
            justify-content: center;
        }
    }

    .block {
        margin-left: 100px;
        margin-right: 100px;
        width: 220px;
        border-radius: 4px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: #ffffff;
        text-align: center;
    }

    .isGray {
        background-image: $grey-gradient;
    }

    .isPurple {
        background-image: $purple-gradient;
        box-shadow: 0 0 11px 0 rgba(151, 140, 215, 0.5);
    }

    .isPink {
        box-shadow: 0 4px 10px 0 rgba(250, 125, 170, 0.5);
        background-image: $pink-gradient;
    }

    .arrowButton {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.disabled {
            pointer-events: none;
            cursor: initial;
            opacity: 0.6;
        }

        .arrowCircle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            padding: 5px;
            border: 1px solid $c-slate-grey;
            border-radius: 50%;
            background-color: #fff;
            font-weight: 500;
            color: $c-slate-grey;
        }
    }

    .iframeContainer {
        min-height: 400px;
        margin-bottom: 30px;

        :global(iframe) {
            width: 100%;
            min-height: 400px;
        }
    }

    .steps {
        display: flex;
        justify-content: center;
    }

    .step {
        border-radius: 2.5px;
        width: 30px;
        height: 5px;
        background-color: rgba(194, 204, 209, 0.2);
        transition: background-color 0.25s;

        &:not(:last-of-type) {
            margin-right: 15px;
        }

        &.active {
            background-color: #939ca1;
        }
    }
}
