@import "src/styles/variables";

.photoContainer {
    display: flex;
    justify-content: center;
    align-content: center;

    .loaderContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 90px;
        border-radius: 5px;
        border: 1px solid $c-greyish-blue;

        :global(.loader:after) {
            top: calc(50% - 0.5em);
            left: calc(50% - 0.5em);
            width: 1em;
            height: 1em;
            border-width: 0.1em;
        }
    }

    .photo {
        width: 159px;
        height: 90px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 5px;
    }

    &.isPlaceholder {
        border: 1px solid $c-greyish-blue;
        border-radius: 5px;

        .photo {
            background-size: initial;
        }
    }

    .selectIconContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid $c-slate-grey;
        border-radius: 50%;
        background-color: #fff;
        color: #27bd62;

        :global(.icon) {
            font-size: 12px;
        }
    }

    .photo.isSelected {
        opacity: 0.5;
    }
}
