@import "variables";

@import "overrides/variables/index";

@import "react-datepicker/dist/react-datepicker";

@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/_all.sass";
@import "bulma/sass/components/dropdown.sass";
@import "bulma/sass/components/level.sass";
@import "bulma/sass/components/modal.sass";
@import "bulma/sass/components/pagination.sass";
@import "bulma/sass/components/message.sass";
@import "bulma/sass/grid/columns.sass";
@import "bulma/sass/grid/tiles.sass";
@import "bulma/sass/layout/hero.sass";
@import "bulma/sass/elements/button.sass";
@import "bulma/sass/elements/container.sass";
@import "bulma/sass/elements/image.sass";
@import "bulma/sass/elements/other.sass";
@import "bulma/sass/elements/icon.sass";
@import "bulma/sass/elements/table.sass";
@import "bulma/sass/form/_all.sass";

@import "overrides/styles/index";

@import "base/base";
@import "base/typography";
@import "upload-modal";
@import "loader";
@import "icon-container";

.photo-pair-container {
    *:focus {
        outline: none;
    }
}
