@import "src/styles/variables";

.blockContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.title {
    margin-bottom: 30px;
    color: $c-dark-purple;
    letter-spacing: 0;
    text-align: center;
}

.blockIndex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 5px;
    border: 1px solid $c-slate-grey;
    border-radius: 50%;
    margin-right: 30px;
    background-color: #fff;
    font-weight: 500;
    color: $c-slate-grey;
}

.block {
    flex-grow: 1;
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #ffffff;
    text-align: center;
}

.isGray {
    background-image: $grey-gradient;
}

.isPurple {
    background-image: $purple-gradient;
    box-shadow: 0 0 11px 0 rgba(151, 140, 215, 0.5);
}

.isPink {
    box-shadow: 0 4px 10px 0 rgba(250, 125, 170, 0.5);
    background-image: $pink-gradient;
}

.blockLoaderContainer {
    position: relative;

    .blockLoader {
        width: 24px;
        height: 24px;

        &::after {
            top: calc(50% - 0.5em);
            left: calc(50% - 0.5em);
            width: 1em;
            height: 1em;
            border-width: 0.1em;
        }
    }
}

.processingIndicator {
    margin-left: 30px;

    :global(.icon) {
        color: #27bd62;
    }
}
