@import "src/styles/variables";

// Workaround for not being able to disable reordering in photos Droppable
// https://github.com/atlassian/react-beautiful-dnd/issues/374
// https://github.com/atlassian/react-beautiful-dnd/issues/216
// https://github.com/atlassian/react-beautiful-dnd/issues/1390
.draggableRoot:not(.isDragging) {
    position: relative;
    transform: none !important;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 5px;

    &:focus {
        outline: none;
    }
}

.isDragging {
    position: relative;
    box-shadow: 0 10px 20px 0 rgba(100, 200, 250, 0.5);

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid #64c8fa;
        content: "";
        border-radius: 5.2px;
    }
}

.countPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    z-index: 10;
    position: relative;
    padding: 5px;
    border: 1px solid #939ca1;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto -30px auto;
    top: -20px;
    font-weight: 500;
    color: #939ca1;
}
