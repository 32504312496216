form .help.is-danger {
    text-align: center;
}

.field:not(:last-child) {
    margin-bottom: 30px;
}

.input.is-rounded {
    height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 2.25em;
    padding-right: 2.25em;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 300;
}

.control .icon {
    top: 0;
    bottom: 0;
    height: auto !important;
    font-size: 1.25rem;
}

label.checkbox.is-danger {
    color: #ff3860;
}

label.checkbox.is-danger input {
    border-color: #ff3860;
}
